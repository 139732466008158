var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('DashboardNavbar',{attrs:{"viewType":_vm.generalSetting.educationalGroupStudents.viewType,"viewTypeStatus":true,"btnAddTitle":_vm.$t('EducationalGroupStudents.add'),"btnAddName":'EducationalGroupStudentAdd',"btnAddStatus":_vm.checkPrivilege(_vm.hasEducationalGroupStudentAdd()),"filterSheetName":'filterSheet',"filterSheetStatus":true},on:{"updateViewType":function($event){return _vm.updateViewType('educationalGroupStudents', $event)}}}),(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('div',{staticClass:"app-content pagination-with-content"},[(_vm.hasData)?[(
          _vm.generalSetting.educationalGroupStudents.viewType == _vm.VIEW_TYPES.table
        )?_c('EducationalGroupStudentTable',{attrs:{"educationalGroupStudentsData":_vm.educationalGroupStudents.educationalGroupStudentsData,"defaultImg":_vm.educationalGroupStudents.educationalGroupStudent.defaultImg,"filterData":_vm.educationalGroupStudents.filterData},on:{"setEducationalGroupStudentData":function($event){return _vm.educationalGroupStudents.educationalGroupStudent.fillData($event)}}}):(
          _vm.generalSetting.educationalGroupStudents.viewType == _vm.VIEW_TYPES.cards
        )?_c('EducationalGroupStudentCards',{attrs:{"educationalGroupStudentsData":_vm.educationalGroupStudents.educationalGroupStudentsData,"defaultImg":_vm.educationalGroupStudents.educationalGroupStudent.defaultImg,"filterData":_vm.educationalGroupStudents.filterData},on:{"setEducationalGroupStudentData":function($event){return _vm.educationalGroupStudents.educationalGroupStudent.fillData($event)}}}):_vm._e(),_c('ActionsData',{attrs:{"actionsData":_vm.educationalGroupStudents.educationalGroupStudent}}),_c('EducationalGroupStudentInfo',{attrs:{"educationalGroupStudent":_vm.educationalGroupStudents.educationalGroupStudent}})]:_vm._e(),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg,"image":_vm.exceptionImg}}):_vm._e(),(_vm.hasData)?_c('CustomPagination',{attrs:{"paginationData":_vm.educationalGroupStudents.filterData},on:{"changePagination":function($event){return _vm.changePagination($event)}}}):_vm._e(),_c('EducationalGroupStudentFilter',{attrs:{"theFilterData":_vm.educationalGroupStudents.filterData},on:{"search":function($event){return _vm.search($event)}}}),_c('EducationalGroupStudentAdd',{attrs:{"educationalGroupStudent":_vm.educationalGroupStudents.educationalGroupStudent,"tokensData":{
        educationalGroupToken: _vm.educationalGroupToken,
        educationalCategoryToken: _vm.educationalCategoryToken,
      }},on:{"refresh":function($event){return _vm.getAllEducationalGroupStudents()}}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }